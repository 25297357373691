<template>
  <div>
    <a-card :title="$t('title.bannerList')" class="content">
      <div slot="extra" href="#">
        <a-button icon="plus" style="float:right" type="link" @click="handleBannerListAdd()"
          >{{ $t('button.add') }}
        </a-button>
      </div>
      <a-table
        v-if="bannerList.length > 0"
        :columns="bannerListColumns"
        :dataSource="bannerList"
        :pagination="false"
        :row-key="(record, index) => index"
        :bordered="true"
      >
        <div slot="picUrl" slot-scope="text">
          <img :src="text" style="max-width:150px;max-height: 100px" />
        </div>
        <div slot="linkParamList" slot-scope="text, record" style="width: 100%;word-wrap:break-word;">
          <a-row :gutter="24">
            <a-col :md="20" :sm="24">
              <div style="text-wrap: unrestricted" v-for="(item, index) in record.linkParamList" :key="index">
                <span> {{ $t('field.' + item.linkParamName) }}: </span>
                <span v-if="record.linkTargetType === 'H5页面'">
                  <a :href="item.linkParamValue" target="_blank">{{ item.linkParamValue }}</a>
                </span>
                <span v-else>
                  {{ item.linkParamValue }}
                </span>
              </div>
            </a-col>
            <a-col :md="4" :sm="24">
              <!--              <a-button icon="plus≠≠" style="float:right" type="link" @click="handleSelectBannerParam(record)">-->
              <!--                {{ $t('button.select') }}-->
              <!--              </a-button>-->
            </a-col>
          </a-row>
        </div>
        <template slot="action" slot-scope="text, record">
          <a vv-if="$auth('table.homeTemplate.edit')" @click="handleBannerListEdit(record)">{{ $t('button.edit') }}</a>
          <a-divider type="vertical" />
          <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteBannerList(record.bannerId)">
            <a href="javascript:;">{{ $t('button.delete') }}</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <SelectCampaign
      ref="selectCampaign"
      :marketplaceCode="marketplaceCode"
      @handleCampaignSelectedOk="handleCampaignSelectedOk"
    ></SelectCampaign>
    <BannerEdit ref="bannerEdit" :marketplaceCode="marketplaceCode" @handleBannerSelectedOk="handleBannerSelectedOk" />
  </div>
</template>
<script>
import BannerEdit from '@/views/cms/components/BannerEdit'
import SelectCampaign from '@/views/component/SelectCampaign'

export default {
  name: 'BannerListEdit',
  props: {
    bannerList: {
      type: Array
    },
    marketplaceCode: {
      type: String
    }
  },
  components: { BannerEdit, SelectCampaign },
  data() {
    return {
      banner: { linkParamList: [] },
      currentBanner: {},
      bannerListColumns: [
        {
          defaultTitle: '连接目标类型',
          title: this.$t('columnName.linkTargetType'),
          dataIndex: 'linkTargetType',
          key: 'linkTargetType',
          align: 'left',
          scopedSlots: {
            customRender: 'linkTargetType'
          }
        },
        {
          defaultTitle: '图片URL',
          title: this.$t('columnName.picUrl'),
          dataIndex: 'picUrl',
          key: 'picUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'picUrl'
          }
        },
        {
          defaultTitle: '链接参数',
          title: this.$t('columnName.linkParamList'),
          dataIndex: 'linkParamList',
          key: 'linkParamList',
          align: 'left',
          scopedSlots: {
            customRender: 'linkParamList'
          }
        },
        {
          defaultTitle: '操作',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted() {},
  methods: {
    // Banner
    // 确认Campaign弹框
    handleCampaignSelectedOk(selectedCampaign) {
      console.debug('handleCampaignSelectedOk', selectedCampaign)
      this.currentBanner.linkParamList = [
        {
          linkParamTitle: 'campaignCode',
          linkParamName: 'campaignCode',
          linkParamValue: selectedCampaign.campaignCode
        }
      ]
    },
    handleSelectBannerParam(banner) {
      this.currentBanner = banner
      this.$refs.selectCampaign.handleSelectCampaignShow()
    },
    handleBannerListAdd() {
      this.banner = { bannerId: new Date().getTime(), linkParamList: [], picUrl: '' }
      this.$refs.bannerEdit.handleBannerEditShow(this.banner)
    },
    handleBannerListEdit(record) {
      this.banner = record
      console.log('handleBannerListEdit', record)
      this.$refs.bannerEdit.handleBannerEditShow(record)
    },
    handleBannerSelectedOk(banner) {
      if (
        !!this.bannerList.filter(function(item) {
          return item.bannerId == banner.bannerId
        }).length
      ) {
        this.bannerList.forEach(el => {
          if (el.bannerId == banner.bannerId) {
            el = banner
          }
        })
      } else {
        this.bannerList.push(banner)
      }
    },
    onDeleteBannerList(bannerId) {
      const list = this.bannerList.filter(item => item.bannerId !== bannerId)
      this.bannerList.splice(0, this.bannerList.length)
      list.forEach(item => {
        this.bannerList.push(item)
      })
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>

<style lang="less" scoped>
.content {
  /deep/ .ant-card-body {
    width: 100%;
    overflow-x: auto;
  }
}
</style>
