<template>
  <div>
    <a-modal
      width="80%"
      :confirm-loading="false"
      :title="$t('page.campaign')"
      :visible="campaignModalShow"
      @cancel="handleCampaignEditCancel"
      @ok="
        campaignModalShow = false
        $emit('handleCampaignSelectedOk', selectedCampaignList)
      "
    >
      <a-row :gutter="24">
        <!-- <a-col :md="8" :sm="24">
          <a-form-item
            :allow-clear="true"
            :label="$t('field.campaignTypeCode')"
            :labelCol="{ lg: { span: 10 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 14 }, sm: { span: 17 } }"
          >
            <a-select mode="default" v-model="queryParam.campaignTypeCode">
              <a-select-option
                v-for="(item, index) in codeList.campaignTypeList"
                :key="index"
                :item="item"
                :campaignTypeName="item.campaignTypeName"
                :campaignTypeCode="item.campaignTypeCode"
                :campaignTypeId="item.campaignTypeId"
                :value="item.campaignTypeCode"
                >{{ item.campaignTypeCode }}-{{ item.campaignTypeName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col> -->
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.campaignName')"
            :labelCol="{ lg: { span: 10 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 14 }, sm: { span: 17 } }"
          >
            <a-input
              
              name="queryParam.campaignName"
              v-model="queryParam.campaignName"
              :placeholder="'请输入' + this.$t('field.campaignName')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.campaignCode')"
            :labelCol="{ lg: { span: 10 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 14 }, sm: { span: 17 } }"
          >
            <a-input
              
              name="queryParam.campaignCode"
              v-model="queryParam.campaignCode"
              :placeholder="'请输入' + this.$t('field.campaignCode')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :md="24" :sm="24" style="text-align: center; margin-bottom: 20px">
          <span class="table-page-search-submitButtons">
            <a-button icon="search" type="primary" @click="searchCampaign">{{ $t('button.search') }}</a-button>
          </span>
        </a-col>
      </a-row>
      <a-table
        :columns="campaignListColumns"
        :dataSource="campaignList"
        :rowSelection="rowSelection"
        :row-key="record => record.campaignId"
        :bordered="true"
        :pagination="false"
        :loading="loadingTable"
      >
        <div slot="picUrl" slot-scope="text">
          <img :src="text" style="max-width:150px;max-height: 100px" />
        </div>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { searchCampaign } from '@/api/marketing/campaign'
import { listCampaignType } from '@/api/marketing/campaignType'

export default {
  name: 'SelectCampaign',
  props: {
    marketplaceCode: {
      type: String
    }
  },
   computed: {
    rowSelection() {
      return {
        type: 'radio',
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onCampaignSelectChange,
        getCheckboxProps: record => ({
          props: {
            disabled: !!record.disabled,
            defaultChecked: !!record.disabled
          }
        })
      }
    }
  },
  data() {
    return {
      loadingTable: false,
      campaignModalShow: false,
      selectedCampaignIdList: [],
      selectedRowKeys: [],
      keyId: [], //keyId => selectedRowKeys 弹窗打开前的保存副本 筛选条件变更重置selectedRowKeys
      selectedCampaignList: [],
      queryParam: {},
      campaignList: [],
      campaignListColumns: [
        {
          defaultTitle: '促销编码',
          title: this.$t('columnName.campaignCode'),
          dataIndex: 'campaignCode',
          key: 'campaignCode',
          align: 'left'
        },
        {
          defaultTitle: '促销名称',
          title: this.$t('columnName.campaignName'),
          dataIndex: 'campaignName',
          key: 'campaignName',
          align: 'left'
        },
        {
          defaultTitle: '营销活动说明',
          title: this.$t('columnName.campaignDesc'),
          dataIndex: 'campaignDesc',
          key: 'campaignDesc',
          align: 'left'
        },
        {
          defaultTitle: '图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'picUrl'
          }
        },
        {
          defaultTitle: '操作',
          title: this.$t('button.action'),
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted() {
    // listCampaignType({}).then(res => {
    //   this.codeList.campaignTypeList = res.data
    // })
  },
  watch: {
    campaignModalShow(val) {
      if (!val) {
        //关闭弹窗清空数据
        this.selectedRowKeys = [], this.queryParam = {};
      }
    }
  },
  methods: {
    onCampaignSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedCampaignList = selectedRows
      console.log(this.selectedCampaignList)
    },
    // 新增Campaign弹框
    handleSelectCampaignShow(id = '') {
      // 弹出编辑页面
      this.campaignModalShow = true
      if(id) {
        this.selectedRowKeys = [id]
      }
      this.searchCampaign()
    },
    // 关闭Campaign弹框
    handleCampaignEditCancel() {
      // 弹出编辑页面
      this.campaignModalShow = false
    },
    searchCampaign() {
      let {keyword, campaignTypeCode, campaignCode, campaignName} = this.queryParam
      let param = { keyword, campaignTypeCode, campaignCode, campaignName, marketplaceCode: this.marketplaceCode, pageNo: 1, pageSize: 100  }
      this.loadingTable = true
      this.campaignList = []
      searchCampaign(param)
        .then(res => {
          // console.log(res)
          let list = res.data;
          this.loadingTable = false
          this.campaignList = list
        })
        .catch(res => {
          // console.log(res)
          this.loadingTable = false
          this.$message.error(res.message)
        })
    },
    
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
