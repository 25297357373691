<template>
  <page-header-wrapper>
    <div class="a-card">
      <a-form :form="homeTemplateForm">
        <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
          <div class="content">
            <div class="c-left">
              <a-tabs default-active-key="1">
                <a-tab-pane :forceRender="true" key="1" :tab="$t('title.basicInfo')">
                  <a-form-item
                    has-feedback=""
                    :label="$t('field.homeTemplateNo')"
                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
                  >
                    <a-input
                      v-model="data.homeTemplateNo"
                      v-decorator="[
                        'homeTemplateNo',
                        {
                          rules: $rules([
                            { required: true, message: '请输入' + this.$t('field.homeTemplateNo') },
                            { max: 100, message: this.$t('field.homeTemplateNo') + '长度小于等于100' },
                            {
                              type: 'checkExists',
                              url: '/api/cms/homeTemplate/checkHomeTemplateNoExists',
                              existIdValue: data.homeTemplateId,
                              checkField: 'homeTemplateNo',
                              idFieldName: 'homeTemplateId',
                              tableName: 'homeTemplate'
                            }
                          ])
                        }
                      ]"
                      :maxLength="8"
                      :placeholder="'请输入' + this.$t('field.homeTemplateNo')"
                      name="data.homeTemplateNo"
                    />
                  </a-form-item>
                  <a-form-item
                    has-feedback
                    :label="$t('field.homeTemplateName')"
                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
                  >
                    <a-input
                      v-model="data.homeTemplateName"
                      v-decorator="[
                        'homeTemplateName',
                        {
                          rules: [
                            { required: true, message: '请输入' + this.$t('field.homeTemplateName') },
                            { max: 100, message: this.$t('field.homeTemplateName') + '长度小于等于100' }
                          ]
                        }
                      ]"
                      :placeholder="'请输入' + this.$t('field.homeTemplateName')"
                      name="data.homeTemplateName"
                    />
                  </a-form-item>
                  <!--                  <a-form-item-->
                  <!--                    has-feedback-->
                  <!--                    :label="$t('field.homeTemplateIntro')"-->
                  <!--                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
                  <!--                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
                  <!--                  >-->
                  <!--                    <a-textarea-->
                  <!--                      v-model="data.homeTemplateIntro"-->
                  <!--                      v-decorator="[-->
                  <!--                        'homeTemplateIntro',-->
                  <!--                        {-->
                  <!--                          rules: [-->
                  <!--                            { required: true, message: '请输入' + this.$t('field.homeTemplateIntro') },-->
                  <!--                            { max: 100, message: this.$t('field.homeTemplateIntro') + '长度小于等于100' },-->
                  <!--                          ],-->
                  <!--                        },-->
                  <!--                      ]"-->
                  <!--                      :placeholder="'请输入' + this.$t('field.homeTemplateIntro')"-->
                  <!--                      name="data.homeTemplateIntro"-->
                  <!--                    />-->
                  <!--                  </a-form-item>-->
                  <a-form-item
                    has-feedback
                    :label="$t('field.newUserPromptText')"
                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
                  >
                    <a-textarea
                      v-model="data.newUserPromptText"
                      v-decorator="[
                        'newUserPromptText',
                        {
                          rules: [
                            { required: true, message: '请输入' + this.$t('field.newUserPromptText') },
                            { max: 100, message: this.$t('field.newUserPromptText') + '长度小于等于100' }
                          ]
                        }
                      ]"
                      :placeholder="'请输入' + this.$t('field.newUserPromptText')"
                      name="data.newUserPromptText"
                    />
                  </a-form-item>
                  <a-form-item
                    has-feedback
                    :label="$t('field.homeTemplateIntro')"
                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
                  >
                    <a-textarea
                      v-model="data.homeTemplateIntro"
                      v-decorator="[
                        'homeTemplateIntro',
                        {
                          rules: [
                            { required: true, message: '请输入' + this.$t('field.homeTemplateIntro') },
                            { max: 100, message: this.$t('field.homeTemplateIntro') + '长度小于等于100' }
                          ]
                        }
                      ]"
                      :placeholder="'请输入' + this.$t('field.homeTemplateIntro')"
                      name="data.homeTemplateIntro"
                    />
                  </a-form-item>
                  
                  <SelectMarketplace :data="data" />
                  <!-- <SelectSiteList
                    :search="data.marketplaceCode"
                    :site-code-list="data.siteCodeList"
                    :site-list="data.siteList"
                  /> -->
                  <a-form-item
                    has-feedback
                    :label="$t('field.language')"
                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
                  >
                    <a-select
                      v-decorator="[
                        'language',
                        {
                          rules: [{ required: true, message: '请输入' + this.$t('field.language') }]
                        }
                      ]"
                      v-model="data.language"
                      mode="default"
                    >
                      <a-select-option v-for="(item, index) in codeList.language" :key="index" :value="item.value"
                        >{{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <!--                  <a-form-item-->
                  <!--                    has-feedback-->
                  <!--                    :label="$t('field.guidPageUrl')"-->
                  <!--                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
                  <!--                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
                  <!--                  >-->
                  <!--                    <a-input-->
                  <!--                      v-model="data.guidPageUrl"-->
                  <!--                      v-decorator="[-->
                  <!--                        'guidPageUrl',-->
                  <!--                        {-->
                  <!--                          rules: [-->
                  <!--                            { required: true, message: '请输入' + this.$t('field.guidPageUrl') },-->
                  <!--                            { max: 100, message: this.$t('field.guidPageUrl') + '长度小于等于100' },-->
                  <!--                          ],-->
                  <!--                        },-->
                  <!--                      ]"-->
                  <!--                      :placeholder="'请输入' + this.$t('field.guidPageUrl')"-->
                  <!--                      name="data.guidPageUrl"-->
                  <!--                    />-->
                  <!--                  </a-form-item>-->
                  <!--                  <a-form-item-->
                  <!--                    has-feedback-->
                  <!--                    :label="$t('field.aboutUsPageUrl')"-->
                  <!--                    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
                  <!--                    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
                  <!--                  >-->
                  <!--                    <a-input-->
                  <!--                      v-model="data.aboutUsPageUrl"-->
                  <!--                      v-decorator="[-->
                  <!--                        'aboutUsPageUrl',-->
                  <!--                        {-->
                  <!--                          rules: [-->
                  <!--                            { required: true, message: '请输入' + this.$t('field.aboutUsPageUrl') },-->
                  <!--                            { max: 100, message: this.$t('field.aboutUsPageUrl') + '长度小于等于100' },-->
                  <!--                          ],-->
                  <!--                        },-->
                  <!--                      ]"-->
                  <!--                      :placeholder="'请输入' + this.$t('field.aboutUsPageUrl')"-->
                  <!--                      name="data.aboutUsPageUrl"-->
                  <!--                    />-->
                  <!--                  </a-form-item>-->
                </a-tab-pane>
                <a-tab-pane :forceRender="true" key="7" :tab="$t('title.bannerSetting')">
                  <BannerListEdit
                    :banner-list="data.bannerList"
                    :marketplaceCode="data.marketplaceCode"
                  ></BannerListEdit>
                </a-tab-pane>
                <a-tab-pane :forceRender="true" key="2" :tab="$t('title.picSetting')">
                  <!-- <div :style="{ marginBottom: '10px' }">
                    <PicDisplayEdit :data="data" field-name="guideLinkPicDisplay"></PicDisplayEdit>
                  </div> -->
                  <div :style="{ marginBottom: '10px' }">
                    <PicDisplayEdit :data="data" field-name="couponPicDisplay"></PicDisplayEdit>
                  </div>
                  <!-- <div :style="{ marginBottom: '10px' }">
                    <PicDisplayEdit :data="data" field-name="couponInDetailPicDisplay"></PicDisplayEdit>
                  </div>
                  <div :style="{ marginBottom: '10px' }">
                    <PicDisplayEdit :data="data" field-name="getCouponPromptPicDisplay"></PicDisplayEdit>
                  </div> -->
                </a-tab-pane>
                <a-tab-pane :forceRender="true" key="3" :tab="$t('title.couponSetting')">
                  <CouponListEdit
                    :marketplace-code="data.marketplaceCode"
                    :coupon-list="data.couponList"
                  ></CouponListEdit>
                </a-tab-pane>
                <!--              <a-tab-pane :forceRender="true" key="4" :tab="$t('title.campaignSetting')">-->
                <!--                <CampaignListEdit-->
                <!--                  :marketplace-code="data.marketplaceCode"-->
                <!--                  :campaign-list="data.campaignList"></CampaignListEdit>-->
                <!--              </a-tab-pane>-->
                <a-tab-pane v-if="0" :forceRender="true" key="5" :tab="$t('title.goodsSetting')">
                  <a-tabs default-active-key="1">
                    <a-tab-pane :forceRender="true" key="1" :tab="$t('title.skuList')">
                      <SkuListEdit :sku-list="data.skuList"></SkuListEdit>
                    </a-tab-pane>
                    <a-tab-pane :forceRender="true" key="2" :tab="$t('title.spuList')">
                      <SpuListEdit :spu-list="data.spuList"></SpuListEdit>
                    </a-tab-pane>
                  </a-tabs>
                </a-tab-pane>
                <a-tab-pane :forceRender="true" key="6" :tab="$t('title.noticeSetting')">
                  <StringListEdit :editable="true" :value-list="data.topMessageList" field-name="topMessageList" />
                </a-tab-pane>
                <a-tab-pane v-if="0" :forceRender="true" key="8" :tab="$t('title.topPicDisplayList')">
                  <PicDisplayListEdit
                    :pic-display-list="data.topPicDisplayList"
                    field-name="topPicDisplayList"
                  ></PicDisplayListEdit>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </a-card>
        <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
          <a-button type="primary" style="margin-left: 8px" @click="handleHomeTemplateSubmit" icon="save">{{
            $t('button.submit')
          }}</a-button>
          <a-button
            v-if="data.homeTemplateId"
            type="primary"
            style="margin-left: 8px"
            @click="handlePreview"
            icon="play-circle"
          >
            {{ $t('button.preview') }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <PhonePreview ref="phonePreview" />
  </page-header-wrapper>
</template>

<script>
import { getHomeTemplateData, saveHomeTemplateData } from '@/api/cms/homeTemplate'
import BannerListEdit from '@/views/cms/components/BannerListEdit'
import SkuListEdit from '@/views/component/SkuListEdit'
import SpuListEdit from '@/views/component/SpuListEdit'
import CouponListEdit from '@/views/component/CouponListEdit'
import CampaignListEdit from '@/views/component/CampaignListEdit'
import StringListEdit from '@/views/component/StringListEdit'
import PicDisplayEdit from '@/views/component/PicDisplayEdit'
import PicDisplayListEdit from '@/views/component/PicDisplayListEdit'
import SelectMarketplace from '@/views/component/SelectMarketplace'
import SelectSiteList from '@/views/component/SelectSiteList'
import PhonePreview from '@/views/component/PhonePreview'

export default {
  name: 'EditHomeTemplate',
  components: {
    BannerListEdit,
    PicDisplayEdit,
    SkuListEdit,
    SpuListEdit,
    CouponListEdit,
    CampaignListEdit,
    StringListEdit,
    PicDisplayListEdit,
    SelectMarketplace,
    SelectSiteList,
    PhonePreview
  },
  props: {},
  data() {
    return {
      queryParam: {},
      homeTemplateForm: this.$form.createForm(this),
      data: {
        guidPageUrl: '',
        aboutUsPageUrl: '',
        getCouponPromptPicUrl: '',
        guideLinkPicUrl: '',
        couponPicUrl: '',
        spuList: [],
        skuList: [],
        campaignList: [],
        couponList: [],
        bannerList: [],
        topMessageList: [],
        topPicDisplayList: []
      },
      moment: '',
      selectedList: [],
      loading: false,
      previewUrl: ''
    }
  },
  mounted() {
    console.log(this.$route.query.type)
    if (this.$route.query.type === 'edit') {
      this.getHomeTemplateData({ homeTemplateId: this.$route.query.homeTemplateId })
    } else {
    }
  },
  methods: {
    // 编辑回显数据
    getHomeTemplateData(data) {
      const that = this
      getHomeTemplateData(data)
        .then(res => {
          console.log('获取数据成功', data, res)
          if (!res.siteCodeList) {
            res.siteCodeList = []
          }
          if (!res.siteList) {
            res.siteList = []
          }
          if (!res.topMessageList) {
            res.topMessageList = []
          }
          if (!res.picList) {
            res.picList = []
          }
          if (!res.bannerList) {
            res.bannerList = []
          }
          if (!res.skuList) {
            res.skuList = []
          }
          if (!res.spuList) {
            res.spuList = []
          }
          if (!res.campaignList) {
            res.campaignList = []
          }
          if (!res.couponList) {
            res.couponList = []
          }
          if (!res.topPicDisplayList) {
            res.topPicDisplayList = []
          }
          that.data = res
          this.$nextTick(() => {
            const formData = {}
            Object.keys(that.homeTemplateForm.getFieldsValue()).map(key => (formData[key] = res[key]))
            that.homeTemplateForm.setFieldsValue(formData)
          })
        })
        .catch(res => {
          console.log('获取数据失败', res)
        })
    },
    // handler
    handleHomeTemplateSubmit(event) {
      const that = this
      this.homeTemplateForm.validateFieldsAndScroll((err, values) => {
        console.log('this.homeTemplateForm.validateFieldsAndScroll', err, values)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        const request = Object.assign({ ...that.data, ...that.homeTemplateForm.getFieldsValue() })
        console.debug('handleHomeTemplateSubmit', request)
        // return console.log('handleHomeTemplateSubmit =>', request, that.data);
        saveHomeTemplateData(request)
          .then(res => {
            console.log('saveCouponData:', that.data, res)
            that.$message.success('操作成功')
            this.$router.push({
              path: '/cms/HomeTemplateList',
              name: 'HomeTemplateList'
            })
          })
          .catch(res => {
            that.$message.error('操作失败,' + res.message)
          })
      })
    },
    /**
     * 预览
     */
    handlePreview() {
      this.$refs.phonePreview.show('/#/?homeTemplateId=' + this.data.homeTemplateId)
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
<style lang="less" scoped>
.container .content {
  display: flex;
  .c-left {
    flex: 1;
    overflow-x: auto;
  }
  .c-right {
    margin-left: 10px;
    width: 380px;
  }
}
</style>
