import request from '@/utils/request'

const baseUrl = '/api'

export function saveCampaignTypeData (parameter) {
  let url = baseUrl + '/marketing/campaignType/save'
  if (!parameter.campaignTypeId) {
    url = baseUrl + '/marketing/campaignType/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchCampaignType (parameter) {
  return request({
    url: baseUrl + '/marketing/campaignType/search',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listCampaignType (parameter) {
  return request({
    url: baseUrl + '/marketing/campaignType/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getCampaignTypeData (parameter) {
  return request({
    url: baseUrl + '/marketing/campaignType/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableCampaignType (parameter) {
  return request({
    url: baseUrl + '/marketing/campaignType/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableCampaignType (parameter) {
  return request({
    url: baseUrl + '/marketing/campaignType/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteCampaignType (parameter) {
  return request({
    url: baseUrl + '/marketing/campaignType/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
