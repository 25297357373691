<template>
  <div>
    <a-card :title="$t('title.campaignList')">
      <div slot="extra" href="#">
        <a-button icon="plus" style="float:right" type="link" @click="handleCampaignListAdd()">{{ $t('button.add') }}
        </a-button>
      </div>
      <a-table
        v-if="campaignList.length>0"
        :columns="campaignListColumns"
        :dataSource="campaignList"
        :row-key="(record, index) => index"
        :bordered="true"
        :pagination=" false"
      >
        <template slot="action" slot-scope="text, record">
          <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteCampaign(record.campaignCode)">
            <a href="javascript:;">{{ $t('button.delete') }}</a>
          </a-popconfirm>
        </template>
      </a-table>
      <SelectCampaign
        ref="selectCampaign"
        :marketplaceCode="marketplaceCode"
        @handleCampaignSelectedOk="handleCampaignSelectedOk"></SelectCampaign>
    </a-card>
  </div>
</template>
<script>
import SelectCampaign from '@/views/component/SelectCampaign'

export default {
  name: 'CampaignListEdit',
  props: {
    marketplaceCode: {
      type: String
    },
    campaignList: {
      type: Array
    }
  },
  components: { SelectCampaign },
  data () {
    return {
      selectedCampaign: {},
      campaignListColumns: [
        {
          defaultTitle: '促销编码',
          title: this.$t('columnName.campaignCode'),
          dataIndex: 'campaignCode',
          key: 'campaignCode',
          align: 'left'
        },
        {
          defaultTitle: '促销名称',
          title: this.$t('columnName.campaignName'),
          dataIndex: 'campaignName',
          key: 'campaignName',
          align: 'left'
        },
        {
          defaultTitle: '营销活动说明',
          title: this.$t('columnName.campaignDesc'),
          dataIndex: 'campaignDesc',
          key: 'campaignDesc',
          align: 'left'
        },
        {
          defaultTitle: '图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'picUrl'
          }
        },
        {
          defaultTitle: '操作',
          title: this.$t('button.action'),
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted () {

  },
  methods: {
    handleCampaignListAdd () {
      this.$refs.selectCampaign.handleSelectCampaignShow()
    },
    // 确认Campaign弹框
    handleCampaignSelectedOk (selectedCampaign) {
      if (selectedCampaign instanceof Array) {
        selectedCampaign.forEach(item => {
          if (item instanceof Array) {
            item.forEach(item2 => {
              this.campaignList.push(item2)
            })
          } else {
            this.campaignList.push(item)
          }
        })
      } else {
        this.campaignList.push(selectedCampaign)
      }
      console.log('handleSpuSelectedOk', this.campaignList)
      // 弹出编辑页面
      this.campaignModalShow = false
    },
    onDeleteCampaign (campaignCode) {
      const list = this.campaignList.filter(item => item.campaignCode !== campaignCode)
      this.campaignList.splice(0, this.campaignList.length)
      list.forEach(item => {
        this.campaignList.push(item)
      })
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
