<template>
  <a-modal
    width="80%"
    :confirm-loading="false"
    :title="$t('page.coupon')"
    :visible="couponModalShow"
    @cancel="handleCouponEditCancel"
    @ok="couponModalShow=false;$emit('handleCouponSelectedOk',selectedCouponList)"
  >
    <a-row :gutter="24">
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.couponTypeCode')"
          :labelCol="{ lg: { span: 10 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 14 }, sm: { span: 17 } }"
        >
          <a-select
            :allow-clear="true"
            v-model="queryParam.couponTypeCode"
            mode="default"
            name="couponTypeName"
          >
            <a-select-option
              v-for="(item, index) in codeList.couponTypeList"
              :key="index"
              :item="item"
              :value="item.couponTypeCode"
            >{{ item.couponTypeCode }}-{{ item.couponTypeName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.couponCode')"
          :labelCol="{ lg: { span: 10 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 14 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'queryParam.couponCode',
              {
                rules: [
                  { max: 100, message: this.$t('field.couponCode') + '长度小于等于100' },
                ],
              },
            ]"
            name="queryParam.couponCode"
            v-model="queryParam.couponCode"
            :placeholder="'请输入' + this.$t('field.couponCode')"
          />
        </a-form-item>
      </a-col>
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.couponName')"
          :labelCol="{ lg: { span: 10 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 14 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'queryParam.couponName',
              {
                rules: [
                  { max: 100, message: this.$t('field.couponName') + '长度小于等于100' },
                ],
              },
            ]"
            name="queryParam.couponName"
            v-model="queryParam.couponName"
            :placeholder="'请输入' + this.$t('field.couponName')"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :md=" 24" :sm="24" style="text-align: center; margin-bottom: 20px">
        <span
          class="table-page-search-submitButtons">
          <a-button icon="search" type="primary" @click="searchCoupon">{{ $t('button.search') }}</a-button>
        </span>
      </a-col>
    </a-row>
    <a-table
      :columns="couponListColumns"
      :data-source="couponList"
      :row-selection="rowSelection"
      :row-key="(record) => record.couponId"
      :pagination="false"
      :bordered="true"
      :loading="loadingTable"
    >
      <div slot="picUrl" slot-scope="text">
        <img :src="text" style="max-width:150px;max-height: 100px"/>
      </div>
    </a-table>
  </a-modal>
</template>
<script>

import { searchCoupon } from '@/api/marketing/coupon'
import { listCouponType } from '@/api/marketing/couponType'
import { forEach } from 'store/storages/all'

export default {
  name: 'SelectCoupon',
  props: {
    marketplaceCode: {
      type: String
    }
  },
  data () {
    return {
      loadingTable: false,
      couponModalShow: false,
      selectedCouponList: [],
      selectedRowKeys: [],
      keyList: [], // keyList => selectedRowKeys 弹窗打开前的保存副本 筛选条件变更重置selectedRowKeys
      couponList: [],
      queryParam: {},
      couponListColumns: [
        {
          defaultTitle: '优惠券码',
          title: this.$t('columnName.couponCode'),
          dataIndex: 'couponCode',
          key: 'couponCode',
          align: 'left'
        },
        {
          defaultTitle: '优惠券码',
          title: this.$t('columnName.couponName'),
          dataIndex: 'couponName',
          key: 'couponName',
          align: 'left'
        }
        // {
        //   defaultTitle: '图片URL',
        //   title: this.$t('columnName.listPicUrl'),
        //   dataIndex: 'listPicUrl',
        //   key: 'listPicUrl',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'picUrl'
        //   }
        // },
        // {
        //   defaultTitle: '操作',
        //   title: this.$t('button.action'),
        //   dataIndex: 'action',
        //   key: 'action',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'action'
        //   }
        // }
      ]
    }
  },
  created () {

  },
  mounted () {
    listCouponType({}).then(res => {
      this.codeList.couponTypeList = res.data
    })
  },
  watch: {
    couponModalShow (val) {
      if (!val) { // 关闭弹窗清空数据
        this.selectedRowKeys = []
      }
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: record => ({
          props: {
            disabled: !!record.disabled,
            defaultChecked: !!record.disabled
          }
        })
      }
    }
  },
  methods: {
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedCouponList = selectedRows
      this.selectedRowKeys = selectedRowKeys
    },

    // Coupon
    handleSelectCouponShow (arr = []) {
      // 弹出编辑页面
      this.couponModalShow = true
      arr.forEach(el => {
        this.keyList.push(el.couponId)
      })
      this.searchCoupon()
    },
    handleCouponEditCancel () {
      // 弹出编辑页面
      this.couponModalShow = false
    },
    searchCoupon () { // 上层组件打开此弹窗 注入当前选中
      let param = {
        keyword: this.queryParam.keyword,
        couponTypeCode: this.queryParam.couponTypeCode,
        couponCode: this.queryParam.couponCode,
        couponName: this.queryParam.couponName,
        marketplaceCode: this.marketplaceCode,
        approvalStatus: '已通过',
        // couponStatus: '已发布',
        pageNo: 1,
        pageSize: 10
      }
      this.loadingTable = true
      this.couponList = []

      searchCoupon(param)
        .then((res) => {
          // console.log(res)
          let list = res.data
          this.selectedRowKeys = this.keyList,
          list.forEach(el => {
            el.disabled = false
            if (this.keyList.length && this.keyList.includes(el.couponId)) {
              el.disabled = true
            }
          })
          console.log('selectedRowKeys =>', this.selectedRowKeys)
          this.couponList = list
          this.loadingTable = false
})
        .catch((res) => {
          // console.log(res)
          this.loadingTable = false
          this.$message.error(res.message)
        })
    },
    onCouponSelectChange (selectedRowKeys, selectedRows) {
      this.selectedCouponList = selectedRows
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
