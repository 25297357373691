<template>
  <a-modal
    :confirm-loading="false"
    width="80%"
    :title="$t('page.bannerList')"
    :visible="bannerModalShow"
    @cancel="handleBannerEditCancel"
    @ok="handleBannerSelectedOk"
  >
    <a-form-item
      :label="$t('field.linkTargetType')"
      :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
      :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
    >
      <a-select
        v-model="banner.linkTargetType"
        :showSearch="true"
        mode="default"
      >
        <a-select-option
          v-for="(item, index) in codeList.linkTargetType"
          :key="index"
          :item="item"
          :value="item.value"
        >{{ item.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <PicUrlEdit ref="picUrlEdit" field-name="picUrl" :data="banner"></PicUrlEdit>
    <a-form-item
      v-if="banner.linkTargetType==='H5页面'"
      :label="$t('field.h5PageUrl')"
      :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
      :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
    >
      <a-input
        style="width: 400px"
        v-model="h5PageUrl"
        v-decorator="[
          'h5PageUrl',
          {
            rules: [
              { required: true, message: '请输入' + this.$t('field.h5PageUrl') },
              { max: 100, message: this.$t('field.h5PageUrl') + '长度小于等于100' },
            ],
          },
        ]"
        :placeholder="'请输入' + this.$t('field.h5PageUrl')"
        name="data.homeTemplateName"
      />
    </a-form-item>
    <a-form-item
      :label="$t('field.campaignName')"
      v-if="banner.linkTargetType==='促销活动'">
      <a-row :gutter="24">
        <a-col :md="20" :sm="24">
          <div v-if="this.selectedCampaign.campaignCode">
            [{{ this.selectedCampaign.campaignTypeName }}]
            - [{{ this.selectedCampaign.campaignCode
            }}]
            -{{ this.selectedCampaign.campaignName }}
          </div>
        </a-col>
        <a-col :md="4" :sm="24">
          <a-button icon="plus" style="float:right" type="link" @click="handleSelectBanner()">
            {{ $t('button.select') }}
          </a-button>
        </a-col>
      </a-row>
      <a-row :gutter="24" v-if="this.selectedCampaign.listPicUrl">
        <a-col :md="20" :sm="24">
          <img :src="this.selectedCampaign.listPicUrl" style="max-height:100px"/>
        </a-col>
      </a-row>
      <a-row :gutter="24" v-else>
        <a-col :md="20" :sm="24">
          <img
            v-for="(img, idx) in this.selectedCampaign.picList"
            :key="idx"
            :src="img"
            style="max-height:100px"/>
        </a-col>
      </a-row>
      <SelectCampaign
        ref="selectCampaign"
        :marketplaceCode="marketplaceCode"
        @handleCampaignSelectedOk="handleCampaignSelectedOk"></SelectCampaign>
    </a-form-item>
  </a-modal>
</template>
<script>
import PicUrlEdit from '@/views/component/PicUrlEdit'
import SelectCampaign from '@/views/component/SelectCampaign'

export default {
  name: 'BannerEdit',
  props: {
    marketplaceCode: {
      type: String
    }
  },
  components: { PicUrlEdit, SelectCampaign },
  data () {
    return {
      bannerModalShow: false,
      selectedCampaign: {},
      h5PageUrl: '',
      banner: {}
    }
  },
  mounted () {
  },
  watch: {
    bannerModalShow(val) {
      if(!val) {
        this.h5PageUrl = '', this.selectedCampaign = {}, this.banner = {};
      }
    },
    h5PageUrl(val) {
      console.log('h5PageUrl =>', val)
    }
  },
  methods: {
    handleSelectBanner () {
      this.$refs.selectCampaign.handleSelectCampaignShow(this.selectedCampaign.campaignId)
    },
    handleBannerEditShow (banner) {
      console.log('banner =>', banner)
      this.banner = banner
      // this.selectedCampaign = {}
      // [{{ this.selectedCampaign.campaignTypeName }}]
      //       - [{{ this.selectedCampaign.campaignCode
      //       }}]
      //       -{{ this.selectedCampaign.campaignName }}
      if (this.banner && this.banner.linkParamList && this.banner.linkParamList.length > 0) {
        if (this.banner.linkTargetType === '促销活动') {
          let {linkParamValue: campaignCode, linkParamName: campaignTypeName, linkParamTitle: campaignName} = this.banner.linkParamList[0]
          // this.selectedCampaign.campaignCode = this.banner.linkParamList[0].linkParamValue
          // this.selectedCampaign.campaignTypeName = 
          this.selectedCampaign = {campaignCode, campaignTypeName, campaignName}
        } else if (this.banner.linkTargetType === 'H5页面') {
          console.log('handleBannerEditShow => ', this.banner)
          this.h5PageUrl = this.banner.linkParamList[0].h5PageUrl
        }
      }
      // 弹出编辑页面
      this.bannerModalShow = true
      this.$nextTick(() => {
        if (this.banner.picUrl) {
          let picUrl = this.banner.picUrl
          this.banner.picUrl = ''
          this.banner.picUrl = picUrl
        } else {
          this.$refs.picUrlEdit.reset()
        }
      })
    },
    handleBannerEditCancel () {
      // 弹出编辑页面
      this.bannerModalShow = false
    },
    handleBannerSelectedOk () {
      if (this.banner.linkTargetType === '促销活动' && this.selectedCampaign && this.selectedCampaign.campaignCode) {
        if (this.selectedCampaign.picList && this.selectedCampaign.picList.length > 0 && !this.banner.picUrl) {
          this.banner.picUrl = this.selectedCampaign.picList[0]
        } else if (this.selectedCampaign.listPicUrl) {
          this.banner.picUrl = this.selectedCampaign.listPicUrl
        }
        this.banner.linkParamList = [{
          linkParamTitle: this.selectedCampaign.campaignName,
          linkParamName: 'campaignCode',
          // linkParamName: this.selectedCampaign.campaignTypeName,
          linkParamValue: this.selectedCampaign.campaignCode
        }]
      } else if (this.banner.linkTargetType === 'H5页面') {
        this.banner.linkParamList = [{
          linkParamTitle: 'campaignCode',
          linkParamName: 'h5PageUrl',
          linkParamValue: this.h5PageUrl
        }]
        this.banner.h5PageUrl = this.h5PageUrl
      }
      this.bannerModalShow = false
      console.log(this.banner)
      this.$emit('handleBannerSelectedOk', this.banner)
    },
    // 确认Campaign弹框
    handleCampaignSelectedOk (selectedCampaign) {
      
      if (selectedCampaign instanceof Array) {
        this.selectedCampaign = selectedCampaign[0]
      } else {
        this.selectedCampaign = selectedCampaign
      }
      console.log('handleCampaignSelectedOk =>', this.selectedCampaign)
      console.debug('handleCampaignSelectedOk', this.selectedCampaign)
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
