<template>
  <div>
    <a-card :title="$t('title.couponList')">
      <div slot="extra" href="#">
        <a-button icon="plus" style="float:right" type="link" @click="handleCouponListAdd()">{{ $t('button.add') }}
        </a-button>
      </div>
      <a-table
        v-if=" couponList.length>0"
        :columns="couponListColumns"
        :dataSource="couponList"
        :row-key="(record) => record.spuId"
        :pagination="false"
        :bordered="true"
      >
        <div slot="picUrl" slot-scope="text">
          <img :src="text" style="max-width:150px;max-height: 100px"/>
        </div>
        <template slot="action" slot-scope="text, record">
          <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteCoupon(record.couponCode)">
            <a href="javascript:;">{{ $t('button.delete') }}</a>
          </a-popconfirm>
        </template>
      </a-table>
      <SelectCoupon
        ref="selectCoupon"
        :marketplaceCode="marketplaceCode"
        @handleCouponSelectedOk="handleCouponSelectedOk"></SelectCoupon>
    </a-card>
  </div>
</template>
<script>
import SelectCoupon from '@/views/component/SelectCoupon'

export default {
  name: 'CouponListEdit',
  props: {
    marketplaceCode: {
      type: String
    },
    couponList: {
      type: Array
    }
  },
  components: { SelectCoupon },
  data () {
    return {
      couponListColumns: [
        {
          defaultTitle: '优惠券码',
          title: this.$t('columnName.couponCode'),
          dataIndex: 'couponCode',
          key: 'couponCode',
          align: 'left'
        },
        {
          defaultTitle: '优惠券码',
          title: this.$t('columnName.couponName'),
          dataIndex: 'couponName',
          key: 'couponName',
          align: 'left'
        },
        {
          defaultTitle: '图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'picUrl'
          }
        },
        {
          defaultTitle: '操作',
          title: this.$t('button.action'),
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    handleCouponListAdd () {
      this.selectedCoupon = {}
      this.$refs.selectCoupon.handleSelectCouponShow(this.couponList)
    },
    handleCouponSelectedOk (selectedCoupon) {
      console.log('handleCouponSelectedOk => ', selectedCoupon, this.couponList)
      selectedCoupon.forEach(item => {
        if(!this.couponList.filter(function(el) {return item.couponId == el.couponId}).length) {
          this.couponList.push(item)
        }
      })
      // 弹出编辑页面
      this.couponModalShow = false
    },
    onDeleteCoupon (couponCode) {
      const list = this.couponList.filter(item => item.couponCode !== couponCode)
      this.couponList.splice(0, this.couponList.length)
      list.forEach(item => {
        this.couponList.push(item)
      })
    }

  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
