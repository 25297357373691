import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function getCampaignDetail (parameter) {
  return request({
    url: baseUrl + '/marketing/campaign/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function saveCampaignData (parameter) {
  let url = baseUrl + '/marketing/campaign/save'
  if (!parameter.campaignId) {
    url = baseUrl + '/marketing/campaign/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchCampaign (parameter) {
  return request({
    url: baseUrl + '/marketing/campaign/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listCampaign (parameter) {
  return request({
    url: baseUrl + '/marketing/campaign/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function approvalCampaign (parameter) {
  return request({
    url: baseUrl + '/marketing/campaign/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}
export function getCampaignData (parameter) {
  return request({
    url: baseUrl + '/marketing/campaign/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableCampaign (parameter) {
  return request({
    url: baseUrl + '/marketing/campaign/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableCampaign (parameter) {
  return request({
    url: baseUrl + '/marketing/campaign/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
